import cn from 'classnames'
import { theme } from '../../styles/theme'
import RichContent from '../RichContent'
import Section from '../Section'
import ResponsiveImage from '../ResponsiveImage'
import Button from '../Button'
import gsap from 'gsap'
import { useMemo, useEffect, useRef, useCallback } from 'react'
import Link from '../Link'
import ReactPlayer from 'react-player/vimeo'

import { createUseStyles } from '../../helpers/createStyles'
import useScrollTrigger from '../../hooks/useScrollTrigger'

export default function ThreeColumnContent ({ data }) {
  const {
    columnOneContent,
    columnTwoContent,
    columnThreeContent,
    include_image: hasImage,
    image,
    image_link: imageLink,
    link,
    include_video: hasVideo,
    columnThreeVideo
  } = data
  const styles = useStyles()

  const videoRef = useRef()

  const initVideo = useCallback(async () => {
    const timeline = gsap.timeline()
    timeline.fromTo(videoRef.current, { opacity: 0 }, { opacity: 1, duration: 2, ease: 'expo.inOut' })
  }, [videoRef])

  if (hasVideo) {
    useEffect(initVideo, [initVideo])
  }

  const sectionRef = useScrollTrigger(
    () => ({
      trigger: sectionRef.current,
      start: () => `${window.innerHeight / 4}px bottom`,
      scrub: false
    }),
    (tl, ref) => {
      const defaults = {
        ease: 'power2.inOut',
        duration: 0.8
      }
      tl.from(ref.current.querySelectorAll('.col-1, .col-2, .col-3'), { y: 40, opacity: 0, stagger: 0.1, ...defaults })
    }
  )

  const column3 = useMemo(() => {
    if (hasImage && !hasVideo && image) {
      return imageLink
        ? (
          <Link
            link={imageLink}
            showText={false}
            className={cn(styles.columnThreeImage, 'col-3')}
          >
            <ResponsiveImage image={image} />
          </Link>
          )
        : (
          <ResponsiveImage
            className={cn(styles.columnThreeImage, 'col-3')}
            image={image}
          />
          )
    }

    if (columnThreeVideo) {
      return (
        <div
          className={cn(styles.columnThree, 'col-3')}>
          <div
            className={styles.columnThreeVideo}
            ref={videoRef}
          >
            <ReactPlayer
              url={`https://player.vimeo.com/video/${columnThreeVideo}`}
              config={{
                vimeo: {
                  playerOptions: {
                    background: true,
                    loop: true,
                    muted: true,
                    playsinline: true
                  }
                }
              }}
              onPlay={initVideo}
            />
          </div>
        </div>
      )
    } else {
      if (image) {
        return imageLink
          ? (
            <Link
              link={imageLink}
              showText={false}
              className={cn(styles.columnThreeImage, 'col-3')}
            >
              <ResponsiveImage image={image} />
            </Link>
            )
          : (
            <ResponsiveImage
              className={cn(styles.columnThreeImage, 'col-3')}
              image={image}
            />
            )
      }
    }

    if (columnThreeContent) {
      return (
        <RichContent
          content={columnThreeContent}
          className={cn(styles.columnThree, 'col-e')}
        />
      )
    }
  }, [])
  return (
    <Section className={cn(styles.content, hasImage && 'has-image', hasVideo && 'has-video')} grid ref={sectionRef}>
      {columnOneContent && (
        <RichContent
          content={columnOneContent}
          className={cn(styles.columnOne, 'col-1')}
        />
      )}
      {columnTwoContent && (
        <div
          className={cn(styles.columnTwo, 'col-2', hasImage && 'extra-padding')}
        >
          <RichContent content={columnTwoContent} />
          {link && <Button size='lg' className={styles.link} link={link} />}
        </div>
      )}
      {column3}
    </Section>
  )
}

const useStyles = createUseStyles({
  content: {
    '&.has-image': {
      '.col-1': {
        order: 2
      },
      '.col-2': {
        order: 3
      },
      '.col-3': {
        order: 1
      },
      [theme.breakpoints.up('md')]: {
        '.col-1': {
          order: 1
        },
        '.col-2': {
          order: 2
        },
        '.col-3': {
          order: 3
        }
      }
    },
    '&.has-video': {
      [theme.breakpoints.up('md')]: {
        '.col-3': {
          marginBottom: 0,
          gridColumn: '8 / span 5'
        }
      }
    }
  },
  columnOne: {
    gridColumn: '1 / span 12',
    [theme.breakpoints.up('md')]: {
      gridRow: 1,
      gridColumn: '1 / span 4'
    }
  },
  columnTwo: {
    display: 'flex',
    flexDirection: 'column',
    gridColumn: '1 / span 12',
    [theme.breakpoints.up('md')]: {
      gridRow: 1,
      gridColumn: '5 / span 3'
    },
    '&.extra-padding': {
      [theme.breakpoints.up('md')]: {
        paddingRight: theme.spacingPx(2)
      }
    }
  },
  columnThree: {
    gridColumn: '1 / span 12',
    [theme.breakpoints.up('md')]: {
      gridRow: 1,
      gridColumn: '8 / span 3'
    }
  },
  columnThreeImage: {
    gridColumn: '1 / span 12',
    marginBottom: theme.spacingPx(2),
    zIndex: 4,
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      gridColumn: '8 / span 5'
    }
  },
  columnThreeVideo: {
    position: 'relative',
    width: 'calc(100vw / 12 * 5 - 32px)',
    height: '100%',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: 'calc(100vw / 16 * 9)'
    },
    ' > div': {
      position: 'absolute',
      top: 0,
      left: 0,
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100% !important',
      height: '100% !important',
      '&:after': {
        position: 'absolute',
        content: '""',
        top: 0,
        left: 0,
        width: '1000%',
        height: '1000%'
      },
      '&:last-child': {
        zIndex: 2
      }
    }
  },
  link: {
    marginTop: theme.spacingPx(4)
  }
})
